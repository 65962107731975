import { useRouter } from 'next/router'
import { PageNotFound } from '@netpurpose/np-ui'
import { LoggedInLayout } from '#components/common/Layout'
import { ShowIfLoggedIn } from '#components/common/ShowIfLoggedIn'

export default function Custom404() {
  const router = useRouter()
  return (
    <ShowIfLoggedIn>
      <LoggedInLayout title="404 - Page not found">
        <PageNotFound backAction={() => router.back()} />
      </LoggedInLayout>
    </ShowIfLoggedIn>
  )
}
