import { FC } from 'react'
import { Icon } from '../Icon'
import {
  BackLink,
  BannerContainer,
  ContentContainer,
  HeaderContainer,
  InfoText,
  LargeText,
  MediumText,
  NotFoundContainer,
  Shadow,
} from './PageNotFound.style'

type Props = {
  show404?: boolean
  infoText?: string
  backAction?: () => void
}

const defaultInfoText = 'The page you’re looking for doesn’t exist.'

const PageNotFound: FC<Props> = ({ show404 = true, backAction, infoText = defaultInfoText }) => (
  <NotFoundContainer>
    <ContentContainer>
      <BannerContainer>
        <Icon
          icon="DataPage"
          alt="Missing web page"
          viewbox="0 0 192 192"
          width={192}
          height={192}
          color="typeTertiary"
        />
        <HeaderContainer>
          {show404 && (
            <LargeText level={2} color="typePrimary">
              404
            </LargeText>
          )}
          <MediumText level={3} color="typeTertiary">
            Page not found
          </MediumText>
        </HeaderContainer>
      </BannerContainer>
      <InfoText level={3} color="typeTertiary">
        {infoText}
        {backAction && (
          <>
            {' '}
            <br />
            <BackLink onClick={backAction}>Go back</BackLink>or choose a new direction from the
            menu.
          </>
        )}
      </InfoText>
      <Shadow />
    </ContentContainer>
  </NotFoundContainer>
)

export default PageNotFound
