import styled from 'styled-components'
import Heading from '../Heading'

export const NotFoundContainer = styled.div`
  display: grid;
  place-items: center;
  height: 100%;
  width: 100%;
  padding: 12.5rem 0;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 60%;
`

export const BannerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
`
export const HeaderContainer = styled.div`
  margin-left: 2.5rem;
`

const Text = styled(Heading)`
  line-height: 1;
  margin-bottom: 0;
`

export const LargeText = styled(Text)`
  font-size: 12rem;
`

export const MediumText = styled(Text)`
  font-size: 3rem;
`

export const InfoText = styled(Heading)`
  text-align: center;
  font-size: 1.5rem;
`

export const BackLink = styled.button`
  display: inline-block;
  color: ${({ theme }) => theme.colors.actionLink};
  background: none;
  border: none;
  font-size: inherit;
  font-weight: inherit;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const Shadow = styled.div`
  --height: 130px;
  position: absolute;
  bottom: calc(var(--height) * -1 - 70px);
  left: 0;
  width: 100%;
  height: var(--height);
  background: radial-gradient(
    50% 50% at 50% 50%,
    ${({ theme }) => theme.colors.architecture8} 0%,
    rgba(6, 9, 111, 0) 100%
  );
  opacity: 0.1;
`
